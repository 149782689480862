import { pathOr } from "ramda"

export const getAfcImage = (data, type) =>
  pathOr(
    null,
    ["acf", "image", "localFile", "childImageSharp", `${type === "fixed" ? "fixed" : "fluid"}`],
    data
  )

export const getImage = (data, type) =>
  pathOr(null, ["localFile", "childImageSharp", `${type === "fixed" ? "fixed" : "fluid"}`], data)

export const getPageNode = (data, query) => pathOr(null, [query, "edges", "0", "node"], data)
