import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
// import { graphql } from "gatsby"
import { Wrapper } from "../../components/Wrapper";
import parse from "html-react-parser";
import styled from "@emotion/styled";
// import Img from "gatsby-image/withIEPolyfill";
import { getPageNode } from "../../helpers/pathExtractor";

export default function UclaniSePage({ data }) {
  const queryData = getPageNode(data, "allWordpressPage");

  const {
    title,
    content,
    acf: {
      file: {
        url: { link },
      },
    },
  } = queryData;

  return (
    <Layout>
      <SEO title="Članstvo" />
      <Wrapper>
        <Title>{title}</Title>
        <SignupSection>{parse(content)}</SignupSection>
        <DownloadButton href={link} target="_blank" download>
          Pristupnica
        </DownloadButton>
      </Wrapper>
    </Layout>
  );
}

export const query = graphql`
  {
    allWordpressPage(filter: { path: { eq: "/uclani-se/" } }) {
      edges {
        node {
          id
          title
          content
          acf {
            file {
              url {
                link
              }
            }
          }
        }
      }
    }
  }
`;

const Title = styled.h1`
  margin-top: 4rem;
`;

const DownloadButton = styled.a`
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
`;

const SignupSection = styled.section`
  max-width: 980px;
  margin: 0 auto;
  text-align: center;
  height: 400px;
  display: flex;
  align-items: center;

  p {
    font-size: 3rem;
    line-height: 4rem;
  }
`;
